<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'CricketLayout',
  // metaInfo: {
  //   title: `${locale.getMessage('meta.cricket_title')}`,
  //   titleTemplate: `%s`,
  //   meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.cricket_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.default_keyword')}` }],
  //   link: [{ rel: 'canonical', href: `https://JAYA9.com/cricket/` }]
  // },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>
